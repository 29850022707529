<template>
    <!-- start inner banner  -->
    <section class="inner-banner">

        <h1>Edit Vacancy `{{ vacancy.title }}`</h1>

        <div class="container">
            <div class="row justify-content-md-center">
                <form @submit.prevent="updateVacancy" class="w-75">
                    <div class="form-group">
                        <input v-model.trim="vacancy.title" type="text" class="form-control" placeholder="Vacancy Title">
                        <div v-if="errors[`title`]" v-for="error in errors[`title`]" :key="error" class="text-danger">{{
                                error
                            }}
                        </div>
                    </div>

                    <div v-if="changeImage || !vacancy.image" class="mb-3">
                        <div class="input-group">
                            <div class="custom-file">
                                <label class="custom-file-label" for="image">{{ file?.name || 'Choose file' }}</label>
                                <input type="file" class="custom-file-input" id="image"
                                       accept="image/png,image/jpg,image/jpeg"
                                       @change="file = $event.target.files[0]">
                            </div>
                        </div>
                        <div v-if="errors[`image`]" v-for="error in errors[`image`]" :key="error" class="text-danger">
                            {{error }}
                        </div>
                    </div>

                    <div v-else class="form-group row">
                        <a @click.prevent="changeImage=true" href=""><h2 class="text-success">Change File</h2></a>
                        <img class=" ml-4 mt-1" :src="vacancy.image" :alt="vacancy.img"
                             style="max-height: 18rem; max-width: 18rem; object-fit: scale-down;">
                    </div>

                    <div class="form-group">
                        <input type="number" class="form-control" v-model="vacancy.hours_per_week_from"
                               placeholder="Hours per week from">
                        <div v-if="errors[`hours_per_week_from`]" v-for="error in errors[`hours_per_week_from`]" :key="error"
                             class="text-danger">{{ error }}
                        </div>
                    </div>

                    <div class="form-group">
                        <input type="number" class="form-control" v-model="vacancy.hours_per_week_to"
                               placeholder="Hours per week to">
                        <div v-if="errors[`hours_per_week_to`]" v-for="error in errors[`hours_per_week_to`]" :key="error"
                             class="text-danger">{{ error }}
                        </div>
                    </div>

                    <div class="form-group">
                        <input type="text" class="form-control" v-model="vacancy.start" aria-describedby="startHelp"
                               placeholder="Start">
                        <small id="startHelp" class="form-text text-muted">ASAP or ...</small>
                        <div v-if="errors[`start`]" v-for="error in errors[`start`]" :key="error" class="text-danger">{{
                                error
                            }}
                        </div>
                    </div>

                    <div class="form-group">
                        <datepicker class="form-control"
                                    :value="vacancy.start_date"
                                    @update:modelValue="vacancy.start_date = moment($event).format('yyyy-MM-DD')"
                                    placeholder="Start date"
                        />
                        <div v-if="errors[`start_date`]" v-for="error in errors[`start_date`]" :key="error"
                             class="text-danger">{{
                                error
                            }}
                        </div>
                    </div>

                    <div class="form-group">
                        <datepicker class="form-control"
                                    :value="vacancy.end_date"
                                    @update:modelValue="vacancy.end_date = moment($event).format('yyyy-MM-DD')"
                                    placeholder="Start date"
                        />
                        <div v-if="errors[`end_date`]" v-for="error in errors[`end_date`]" :key="error" class="text-danger">{{
                                error
                            }}
                        </div>
                    </div>

                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" v-model="vacancy.is_public" id="ispublic">
                        <label class="form-check-label" for="ispublic">Public</label>
                        <div v-if="errors[`is_public`]" v-for="error in errors[`is_public`]" :key="error" class="text-danger">
                            {{
                                error
                            }}
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Vacancy Description</label>
                        <vue-editor v-model="vacancy.description"></vue-editor>
                        <div v-if="errors[`description`]" v-for="error in errors[`description`]" :key="error"
                             class="text-danger">{{
                                error
                            }}
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Vacancy Requirements</label>
                        <vue-editor v-model="vacancy.requirements"></vue-editor>
                        <div v-if="errors[`requirements`]" v-for="error in errors[`requirements`]" :key="error"
                             class="text-danger">
                            {{ error }}
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Vacancy Responsibilities</label>
                        <vue-editor v-model="vacancy.responsibilities"></vue-editor>
                        <div v-if="errors[`responsibilities`]" v-for="error in errors[`responsibilities`]" :key="error"
                             class="text-danger">{{ error }}
                        </div>
                    </div>

                    <div class="form-group">
                        <label>We offer</label>
                        <vue-editor v-model="vacancy.we_offer"></vue-editor>
                        <div v-if="errors[`we_offer`]" v-for="error in errors[`we_offer`]" :key="error" class="text-danger">{{
                                error
                            }}
                        </div>
                    </div>

                    <div class="form-group">
                        <input type="text" class="form-control" v-model="vacancy.salary_description"
                               placeholder="Salary description">
                        <div v-if="errors[`salary_description`]" v-for="error in errors[`salary_description`]" :key="error"
                             class="text-danger">{{ error }}
                        </div>
                    </div>

                    <div class="form-group">
                        <Multiselect
                            v-model="vacancy.industry_id"
                            :options="industries"
                            :placeholder="$t('Select industry')"
                            class="form-control"
                            :searchable="true"
                        />
                        <div v-if="errors[`industry_id`]" v-for="error in errors[`industry_id`]" :key="error" class="text-danger">
                            {{ error }}
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Overtime possible</label>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="overtime_possible" class="custom-control-input"
                                   v-model="vacancy.overtime_possible" value="no">
                            <label class="custom-control-label" for="overtime_possible">No</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="overtime_possible2" class="custom-control-input"
                                   v-model="vacancy.overtime_possible" value="yes">
                            <label class="custom-control-label" for="overtime_possible2">Yes</label>
                        </div>
                        <div v-if="errors[`overtime_possible`]" v-for="error in errors[`overtime_possible`]" :key="error"
                             class="text-danger">{{ error }}
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Accommodation possible</label>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="accommodation_possible" class="custom-control-input"
                                   v-model="vacancy.accommodation_possible" value="no">
                            <label class="custom-control-label" for="accommodation_possible">No</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="accommodation_possible2" class="custom-control-input"
                                   v-model="vacancy.accommodation_possible" value="yes">
                            <label class="custom-control-label" for="accommodation_possible2">Yes</label>
                        </div>
                        <div v-if="errors[`accommodation_possible`]" v-for="error in errors[`accommodation_possible`]"
                             :key="error"
                             class="text-danger">{{ error }}
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Accommodation deduction</label>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="accommodation_deduction" class="custom-control-input"
                                   v-model="vacancy.accommodation_deduction" value="no">
                            <label class="custom-control-label" for="accommodation_deduction">No</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="accommodation_deduction2" class="custom-control-input"
                                   v-model="vacancy.accommodation_deduction" value="yes">
                            <label class="custom-control-label" for="accommodation_deduction2">Yes</label>
                        </div>
                        <div v-if="errors[`accommodation_deduction`]" v-for="error in errors[`accommodation_deduction`]"
                             :key="error"
                             class="text-danger">{{ error }}
                        </div>
                    </div>

                    <div class="form-group">
                        <input type="number" class="form-control"
                               v-model="vacancy.if_accommodation_deducted_price_per_week"
                               placeholder="If accommodation deducted price per week">
                        <div v-if="errors[`if_accommodation_deducted_price_per_week`]"
                             v-for="error in errors[`if_accommodation_deducted_price_per_week`]" :key="error"
                             class="text-danger">
                            {{ error }}
                        </div>
                    </div>

                    <div class="form-group">
                        <input type="text" v-model="vacancy.accommodation_type" class="form-control"
                               aria-describedby="accommodationTypeHelp" placeholder="Accomodation type">
                        <small id="accommodationTypeHelp" class="form-text text-muted">Single, Double, etc ...</small>
                        <div v-if="errors[`accommodation_type`]" v-for="error in errors[`accommodation_type`]" :key="error"
                             class="text-danger">{{ error }}
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Transport</label>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="transport" class="custom-control-input" v-model="vacancy.transport"
                                   value="no">
                            <label class="custom-control-label" for="transport">No</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="transport2" class="custom-control-input" v-model="vacancy.transport"
                                   value="yes">
                            <label class="custom-control-label" for="transport2">Yes</label>
                        </div>
                        <div v-if="errors[`transport`]" v-for="error in errors[`transport`]" :key="error" class="text-danger">
                            {{
                                error
                            }}
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Transport expenses covered</label>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="transport_expenses_covered" class="custom-control-input"
                                   v-model="vacancy.transport_expenses_covered" value="no">
                            <label class="custom-control-label" for="transport_expenses_covered">No</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="transport_expenses_covered2" class="custom-control-input"
                                   v-model="vacancy.transport_expenses_covered" value="yes">
                            <label class="custom-control-label" for="transport_expenses_covered2">Yes</label>
                        </div>
                        <div v-if="errors[`transport_expenses_covered`]" v-for="error in errors[`transport_expenses_covered`]"
                             :key="error" class="text-danger">{{ error }}
                        </div>
                    </div>

                    <div class="form-group">
                        <input type="number" v-model="vacancy.number_of_workers_needed" class="form-control"
                               placeholder="Number of workers needed">
                        <div v-if="errors[`number_of_workers_needed`]" v-for="error in errors[`number_of_workers_needed`]"
                             :key="error"
                             class="text-danger">{{ error }}
                        </div>
                    </div>

                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="provide_tools"
                               v-model="vacancy.provide_tools">
                        <label class="form-check-label" for="provide_tools">Provide tools</label>
                        <div v-if="errors[`provide_tools`]" v-for="error in errors[`provide_tools`]" :key="error"
                             class="text-danger">
                            {{ error }}
                        </div>
                    </div>

                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="provide_insurance"
                               v-model="vacancy.provide_insurance">
                        <label class="form-check-label" for="provide_insurance">Provide insurance</label>
                        <div v-if="errors[`provide_insurance`]" v-for="error in errors[`provide_insurance`]" :key="error"
                             class="text-danger">{{ error }}
                        </div>
                    </div>

                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="provide_travel_expenses"
                               v-model="vacancy.provide_travel_expenses">
                        <label class="form-check-label" for="provide_travel_expenses">Provide travel expenses</label>
                        <div v-if="errors[`provide_travel_expenses`]" v-for="error in errors[`provide_travel_expenses`]"
                             :key="error"
                             class="text-danger">{{ error }}
                        </div>
                    </div>

                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="provide_relocation"
                               v-model="vacancy.provide_relocation">
                        <label class="form-check-label" for="provide_relocation">Provide relocation</label>
                        <div v-if="errors[`provide_relocation`]" v-for="error in errors[`provide_relocation`]" :key="error"
                             class="text-danger">{{ error }}
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Provide other</label>
                        <vue-editor v-model="vacancy.provide_other"></vue-editor>
                        <div v-if="errors[`provide_other`]" v-for="error in errors[`provide_other`]" :key="error"
                             class="text-danger">
                            {{ error }}
                        </div>
                    </div>

                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Location" :value="vacancy.location_name"
                               ref="location">
                        <div v-if="errors[`location_name`]" v-for="error in errors[`location_name`]" :key="error"
                             class="text-danger">
                            {{ error }}
                        </div>
                        <div v-if="errors[`location_description`]" v-for="error in errors[`location_description`]"
                             :key="error"
                             class="text-danger">{{ error }}
                        </div>
                        <div v-if="errors[`location_city`]" v-for="error in errors[`location_city`]" :key="error"
                             class="text-danger">
                            {{ error }}
                        </div>
                        <div v-if="errors[`location_country`]" v-for="error in errors[`location_country`]" :key="error"
                             class="text-danger">{{ error }}
                        </div>
                        <div v-if="errors[`location_lat`]" v-for="error in errors[`location_lat`]" :key="error"
                             class="text-danger">
                            {{ error }}
                        </div>
                        <div v-if="errors[`location_lng`]" v-for="error in errors[`location_lng`]" :key="error"
                             class="text-danger">
                            {{ error }}
                        </div>
                        <div v-if="errors[`location_place_id`]" v-for="error in errors[`location_place_id`]" :key="error"
                             class="text-danger">{{ error }}
                        </div>
                    </div>

                    <div class="form-group">
                        <input type="text" class="form-control" v-model="vacancy.phone" placeholder="Phone">
                        <div v-if="errors[`phone`]" v-for="error in errors[`phone`]" :key="error" class="text-danger">{{
                                error
                            }}
                        </div>
                    </div>

                    <div class="form-group">
                        <input type="email" class="form-control" v-model="vacancy.email" placeholder="Email">
                        <div v-if="errors[`email`]" v-for="error in errors[`email`]" :key="error" class="text-danger">{{
                                error
                            }}
                        </div>
                    </div>

                    <div class="form-group">
                        <label><h2>Salaries</h2></label>
                        <span @click="vacancy.salaries.push({})" class="add-field">+ {{ $t('ADD') }}</span>
                        <div v-if="errors[`salaries`]" v-for="error in errors[`salaries`]" :key="error" class="text-danger">{{
                                error
                            }}
                        </div>
                    </div>

                    <div v-for="(salary, index) in vacancy.salaries" :key="index" class="form-group">
                        <div class="row mt-3">
                            <div class="col-md-6">
                                <label>Employment Type</label> <span v-if="index > 0"
                                                                     @click="vacancy.salaries.splice(index,1)"
                                                                     class="add-field">- {{ $t('REMOVE') }}</span>
                                <select class="custom-select form-control"
                                        v-model="vacancy.salaries[index].employment_type_id">
                                    <option :value="undefined">Select Employment Type</option>
                                    <option v-for="employmentType in employmentTypes" :value="employmentType.id">
                                        {{ employmentType.name }}
                                    </option>
                                </select>
                                <div v-if="errors && errors[`salaries.${index}.employment_type_id`]"
                                     v-for="error in errors[`salaries.${index}.employment_type_id`]"
                                     :key="error"
                                     class="text-danger">{{ error.replace(/The.+?salaries[^\s]+/i, '') }}
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-md-3 input-group-sm">
                                <label>From</label>
                                <input v-model="vacancy.salaries[index].amount_from" type="number" step="0.01"
                                       class="form-control"
                                       placeholder="From">
                                <div v-if="errors && errors[`salaries.${index}.amount_from`]"
                                     v-for="error in errors[`salaries.${index}.amount_from`]" :key="error"
                                     class="text-danger">
                                    {{ error.replace(/The.+?salaries[^\s]+/i, '') }}
                                </div>
                            </div>
                            <div class="col-md-3 input-group-sm">
                                <label>To</label>
                                <input v-model="vacancy.salaries[index].amount_to" type="number" step="0.01"
                                       class="form-control"
                                       placeholder="To">
                                <div v-if="errors && errors[`salaries.${index}.amount_to`]"
                                     v-for="error in errors[`salaries.${index}.amount_to`]" :key="error"
                                     class="text-danger">
                                    {{ error.replace(/The.+?salaries[^\s]+/i, '') }}
                                </div>
                            </div>
                            <div class="col-md-2 input-group-sm">
                                <label>Type</label>
                                <select class="custom-select form-control"
                                        v-model="vacancy.salaries[index].employment_period_id">
                                    <option :value="undefined">Select Period</option>
                                    <option v-for="employmentPeriod in employmentPeriods" :value="employmentPeriod.id">
                                        {{ employmentPeriod.name }}
                                    </option>
                                </select>
                                <div v-if="errors && errors[`salaries.${index}.employment_period_id`]"
                                     v-for="error in errors[`salaries.${index}.employment_period_id`]" :key="error"
                                     class="text-danger">{{ error.replace(/The.+?salaries[^\s]+/i, '') }}
                                </div>
                            </div>
                            <div class="col-md-2 input-group-sm">
                                <label>Currency</label>
                                <select class="custom-select form-control" v-model="vacancy.salaries[index].currency">
                                    <option :value="undefined">Select Currency</option>
                                    <option value="EUR">EUR</option>
                                    <option value="RUB">RUB</option>
                                </select>
                                <div v-if="errors && errors[`salaries.${index}.currency`]"
                                     v-for="error in errors[`salaries.${index}.currency`]" :key="error"
                                     class="text-danger">
                                    {{ error.replace(/The.+?salaries[^\s]+/i, '') }}
                                </div>
                            </div>
                            <div class="col-md-2 input-group-sm">
                                <label>Gross/Net</label>
                                <select class="custom-select form-control" v-model="vacancy.salaries[index].type">
                                    <option :value="undefined">Select Gross/Net</option>
                                    <option value="Net">Net</option>
                                    <option value="Gross">Gross</option>
                                </select>
                                <div v-if="errors && errors[`salaries.${index}.type`]"
                                     v-for="error in errors[`salaries.${index}.type`]"
                                     :key="error" class="text-danger">{{ error.replace(/The.+?salaries[^\s]+/i, '') }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label><h2>Languages</h2></label>
                        <span @click="vacancy.languages.push({})" class="add-field">+ {{ $t('ADD') }}</span>
                    </div>

                    <div v-for="(language, index) in vacancy.languages" :key="index" class="form-group">
                        <div class="row mt-3">
                            <div class="col-md-6">
                                <label>Language</label> <span @click="vacancy.languages.splice(index,1)"
                                                              class="add-field">- {{ $t('REMOVE') }}</span>
                                <Multiselect
                                    v-model="vacancy.languages[index].id"
                                    :options="languages"
                                    :placeholder="$t('Select language')"
                                    class="form-control"
                                    :searchable="true"
                                />
                                <div v-if="errors && errors[`languages.${index}.id`]"
                                     v-for="error in errors[`languages.${index}.id`]"
                                     :key="error" class="text-danger">{{ error.replace(/The.+?languages[^\s]+/i, '') }}
                                </div>
                            </div>

                            <div class="col-md-6">
                                <label>Level</label>
                                <Multiselect
                                    v-model="vacancy.languages[index].level"
                                    :options="levels"
                                    :placeholder="$t('Select level')"
                                    class="form-control"
                                />
                                <div v-if="errors && errors[`languages.${index}.level`]"
                                     v-for="error in errors[`languages.${index}.level`]" :key="error"
                                     class="text-danger">
                                    {{ error.replace(/The.+?languages[^\s]+/i, '') }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Tags</label>
                        <smart-tags-component v-if="tags"
                                              :maxTags="5"
                                              :sources="tags"
                                              v-model="vacancy.tags"
                        />
                    </div>

                    <div class="form-group">
                        <div>
                            <label><h2>Custom fields</h2></label>
                            <button @click.prevent="showAddVacancyFields=true" class="btn btn-primary bg-danger mx-2">
                                Add Vacancy Fields
                            </button>
                            <button @click.prevent="showAddApplicationFields=true"
                                    class="btn btn-primary bg-warning mx-2">Add Application Fields
                            </button>
                        </div>

                        <div v-if="vacancy.vacancyCustomFields?.length">
                            <label>Vacancy custom fields</label>
                            <div class="row" v-for="(customField, index) in vacancy.vacancyCustomFields" :key="index">
                                <div class="col-md-6">
                                    <input type="text" class="form-control" v-model="customField.label">
                                    <div v-if="errors && errors[`vacancyCustomFields.${index}.label`]"
                                         v-for="(error, index) in errors[`vacancyCustomFields.${index}.label`]"
                                         :key="index" class="text-danger">
                                        {{ error.replace(/The.+?vacancyCustomFields[^\s]+/i, '') }}
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <input type="text" class="form-control" v-model="customField.value">
                                    <div v-if="errors && errors[`vacancyCustomFields.${index}.value`]"
                                         v-for="(error, index) in errors[`vacancyCustomFields.${index}.value`]"
                                         :key="index" class="text-danger">
                                        {{ error.replace(/The.+?vacancyCustomFields[^\s]+/i, '') }}
                                    </div>
                                </div>
                                <span @click="vacancy.vacancyCustomFields.splice(index,1)"
                                      class="add-field">- {{ $t('REMOVE') }}</span>
                            </div>
                        </div>

                        <div v-if="vacancy.applicationCustomFields?.length">
                            <label>Application custom fields</label>
                            <div class="row" v-for="(customField, index) in vacancy.applicationCustomFields"
                                 :key="index">
                                <div class="col-md-6">
                                    <input type="text" class="form-control" v-model="customField.label">
                                    <div v-if="errors && errors[`applicationCustomFields.${index}.label`]"
                                         v-for="(error, index) in errors[`applicationCustomFields.${index}.label`]"
                                         :key="index" class="text-danger">
                                        {{ error.replace(/The.+?applicationCustomFields[^\s]+/i, '') }}
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <select v-model="customField.inputType" class="custom-select form-control">
                                        <option v-for="customFieldInputType in customFieldInputTypes" :value="customFieldInputType">
                                            {{ customFieldInputType }}
                                        </option>
                                    </select>
                                </div>
                                <div v-if="needValue(customField)" class="col-md-2">
                                    <input type="text" class="form-control" v-model="customField.value">
                                    <div v-if="errors && errors[`applicationCustomFields.${index}.value`]"
                                         v-for="(error, index) in errors[`applicationCustomFields.${index}.value`]"
                                         :key="index" class="text-danger">
                                        {{ error.replace(/The.+?applicationCustomFields[^\s]+/i, '') }}
                                    </div>
                                </div>
                                <span @click="vacancy.applicationCustomFields.splice(index,1)"
                                      class="add-field">- {{ $t('REMOVE') }}</span>
                            </div>
                        </div>
                    </div>

                    <button type="submit" class="btn btn-primary">Update</button>
                </form>
            </div>
        </div>

        <modal-dropdown-component v-if="showAddApplicationFields"
                                  @hideComponent="showAddApplicationFields=false"
                                  title="Application"
                                  :fields="customFields['Application']"
                                  @selected="customFieldSelected"
        />

        <modal-dropdown-component v-if="showAddVacancyFields"
                                  @hideComponent="showAddVacancyFields=false"
                                  title="Vacancy"
                                  :fields="customFields['Vacancy']"
                                  @selected="customFieldSelected"
        />

    </section>
</template>

<script>
import Datepicker from 'vue3-datepicker'
import {VueEditor} from 'vue3-editor'
import {myVacancyService} from "@/services/myVacancyService";
import SmartTagsComponent from "@/components/general/SmartTagsComponent";
import moment from "moment";
import ModalDropdownComponent from "@/components/general/ModalDropdownComponent";
import languageLevels from "@/js/languageLevels";
import Multiselect from '@vueform/multiselect'

export default {
    name: "EditVacancy",
    data() {
        return {
            levels: languageLevels,
            id: this.$route.params.id,
            vacancy: {},
            file: null,
            changeImage: false,

            moment: moment,
            showAddApplicationFields: false,
            showAddVacancyFields: false,
            loaded: false,
        }
    },
    created() {
        alert('If you remove application custom fields, the corresponding application custom field values will also be removed. User discretion is advised.')
        this.getVacancy()
    },
    mounted() {
        this.$loadScript(process.env.VUE_APP_GOOGLE_PLACES_API).then(() => {
                const autocomplete = new google.maps.places.Autocomplete(this.$refs["location"]);

                autocomplete.addListener("place_changed", () => {
                    const {name, formatted_address, address_components, geometry, place_id} = autocomplete.getPlace();
                    this.vacancy.location_name = name
                    this.vacancy.location_description = formatted_address
                    address_components.forEach(adr => {
                        let type = adr.types[0]
                        if (['locality', 'postal_town'].includes(type)) {
                            this.vacancy.location_city = adr.long_name
                        } else if (type === 'country') {
                            this.vacancy.location_country = adr.long_name
                        }
                    })
                    this.vacancy.location_lat = geometry.location.lat()
                    this.vacancy.location_lng = geometry.location.lng()
                    this.vacancy.location_place_id = place_id
                })
            })
    },
    computed: {
        customFields() {
            return this.$store.getters.vacancyCustomFields
        },
        industries() {
            return this.$store.getters.industries
        },
        employmentTypes() {
            return this.$store.getters.employmentTypes
        },
        employmentPeriods() {
            return this.$store.getters.employmentPeriods
        },
        languages() {
            return this.$store.getters.languages
        },
        tags() {
            return this.$store.getters.tags
        },
        customFieldInputTypes() {
            return this.$store.getters.customFieldInputTypes
        }
    },
    methods: {
        updateVacancy() {
            const formData = new FormData();

            if (this.file) {
                formData.append('image', this.file)
            }

            formData.append('data', JSON.stringify(this.vacancy))
            formData.append('_method', 'put') // https://github.com/laravel/framework/issues/13457

            myVacancyService.updateVacancy(this.vacancy.id, formData).then(response => {
                this.errors = []
                alert('Vacancy saved!')
            }).catch(error => {
                this.errors = error.response.data.errors || []
            })
        },
        getVacancy() {
            myVacancyService.getVacancy(this.id).then(response => {
                this.vacancy = response.data.data
            })
        },
        customFieldSelected(customField) {
            const { selectedType, label, inputType, value } = customField
            if (customField.selectedType === 'Application') {
                this.vacancy.applicationCustomFields.push({ selectedType, label, inputType, value })
            }
            if (customField.selectedType === 'Vacancy') {
                this.vacancy.vacancyCustomFields.push({ selectedType, label, inputType, value })
            }
        },
        needValue(customField) {
            if (!['dropdown', 'radio', 'range'].includes(customField.inputType)) {
                customField.value = null
                return false
            }
            return true
        },
    },
    components: {
        Datepicker,
        VueEditor,
        SmartTagsComponent,
        ModalDropdownComponent,
        Multiselect
    }
}
</script>